import { useState } from 'react';
import { Form, useCreate, useNotify, useUpdate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import useBooleanInput from '../../../hooks/useBooleanInput';
import RoutePaths from '../../../routes';
import BillingForm from '../../quotes/quoteFlow/view/BillingForm';
import PaymentForm from '../../quotes/quoteFlow/view/PaymentForm';
import { addPaymentMethodModalStyle } from './add-payment-method-modal-style';

function AddPaymentMethodModal({ onClose, onSubmit }) {
  const classes = addPaymentMethodModalStyle();
  const [create] = useCreate();
  const stripe = useStripe();
  const elements = useElements();
  const notify = useNotify();
  const { state } = useLocation() || {};
  const { user } = state || {};

  const { mailing_address: mailingAddress } = user || {};

  const shippingAddressLabel = mailingAddress
    ? `${mailingAddress.street_1}, ${mailingAddress.street_2}, ${mailingAddress.city}, ${mailingAddress.state_province} ${mailingAddress.zipcode}`.trim()
    : null;

  const [update, { isLoading: isUpdateLoading }] = useUpdate();

  const [isLoading, toggleLoading] = useBooleanInput(false);
  const [nameOnCard, setNameOnCard] = useState('');
  const [errors, setErrors] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] =
    useBooleanInput(true);

  const validBillingAddress =
    isBillingSameAsShipping || (!!billingAddress.address1 && !!billingAddress.city);

  const isFormValid =
    errors.cardNumber &&
    errors.expirationDate &&
    errors.cvc &&
    nameOnCard &&
    validBillingAddress;

  function handleOnCancel() {
    onClose();
  }

  function handleBillingAddressChange(event) {
    setBillingAddress({ ...billingAddress, [event.field]: event.value });
  }

  function handleOnSameAsShippingChange() {
    setIsBillingSameAsShipping();
  }

  function transformAddress(address) {
    return {
      country: address.country || 'US',
      address1: address.street_1,
      address2: address.street_2 || '',
      city: address.city,
      zipcode: address.zipcode,
      state: address.state_province,
    };
  }

  async function handleSubmitValidateAddress() {
    try {
      toggleLoading();
      const addressToBeSent = isBillingSameAsShipping
        ? transformAddress(mailingAddress)
        : billingAddress;

      // Create the payload for the address validation
      const payload = formatAddressPayload(addressToBeSent);
      if (stripe) {
        const tokenResponse = await stripe.createToken(
          elements.getElement(CardNumberElement)
        );

        if (tokenResponse.error) {
          notify(`[SOMETHING WENT WRONG]: ${tokenResponse.error.message}`, {
            type: 'error',
          });
          return;
        }

        // Proceed with API calls to validate the address and update the user
        await validateAddressAndUpdateUser(payload, tokenResponse.token.id);
      } else {
        notify('Stripe is not initialized', { type: 'warning' });
      }
    } catch (err) {
      toggleLoading();
      notify('An unexpected error occurred. Please try again later.', {
        type: 'error',
      });
    }
  }

  function formatAddressPayload(address) {
    return {
      street_1: address.address1,
      street_2: address.address2 || '',
      city: address.city,
      zipcode: address.zipcode || mailingAddress.zipcode,
      state: address.state || mailingAddress.state_province,
    };
  }

  function handleError(error, defaultMessage = 'An unexpected error occurred.') {
    const errorMessage = error?.body?.message || error?.message || defaultMessage;
    notify(`Error: ${errorMessage}`, { type: 'error' });
  }

  async function validateAddressAndUpdateUser(payload, token) {
    try {
      await create(
        `${RoutePaths.addresses}/mailing/validate`,
        {
          data: payload,
        },
        { returnPromise: true }
      );

      await update(
        `users/${user.id}/addresses/mailing`,
        { data: payload },
        {
          onSuccess: () => {
            create(
              `policies/add-update-payment-method`,
              {
                data: {
                  user_id: user.id,
                  billing_information: { ...payload, name_on_card: nameOnCard },
                  stripeToken: token,
                },
              },
              {
                onSuccess: () => {
                  onSubmit({ ...payload, name_on_card: nameOnCard });
                  onClose();
                },
                onError: err => {
                  handleError(err);
                  toggleLoading();
                },
              }
            );
          },
          onError: err => {
            toggleLoading();
            handleError(err);
          },
        }
      );
    } catch (err) {
      toggleLoading();
      notify(`Error: ${err.message}`, { type: 'error' });
    }
  }

  return (
    <Dialog
      open
      onClose={handleOnCancel}
      className={classes.root}
      classes={{ paper: classes.paper }}
    >
      <Form>
        <DialogTitle className={classes.modalTitle}>
          Add a Payment Method
        </DialogTitle>

        <Divider />

        <DialogContent className={classes.modalContent}>
          <PaymentForm
            onNameCardChange={setNameOnCard}
            errors={errors}
            onErrorsChange={setErrors}
            classes={{ root: classes.paymentForm }}
          />

          <BillingForm
            quote={{
              policy_zipcode: mailingAddress.zipcode,
              policy_state: mailingAddress.state_province,
            }}
            onAddressChange={handleBillingAddressChange}
            isBillingSameAsShipping={isBillingSameAsShipping}
            onSameAsShippingChange={handleOnSameAsShippingChange}
            shippingAddress={shippingAddressLabel}
          />
        </DialogContent>

        <DialogActions className={classes.modalActions}>
          <Button
            onClick={handleOnCancel}
            variant='outlined'
            sx={{ height: '40px' }}
          >
            CANCEL
          </Button>

          <Button
            color='primary'
            variant='contained'
            onClick={handleSubmitValidateAddress}
            style={{ marginTop: '32px', marginBottom: '32px', width: '100%' }}
            disabled={!isFormValid || isLoading || isUpdateLoading}
            sx={{ height: '40px' }}
          >
            {isLoading ? (
              <CircularProgress style={{ color: 'white' }} size={20} />
            ) : (
              'ADD CARD'
            )}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
AddPaymentMethodModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
};
AddPaymentMethodModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddPaymentMethodModal;
