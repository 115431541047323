import { getDateAsFormattedLocalTZ, parseAndFormatDate } from '../../shared/utils';
import {
  NO_RESPONSE_FROM_CLINIC,
  RECEIPT_SENT,
  RECORD_REQUEST_CREATED,
  RECORDS_RECEIVED,
  RECORDS_UNAVAILABLE,
  REQUEST_1_SENT,
  REQUEST_2_SENT,
  REQUEST_3_SENT,
} from './constants/medical-records-request-audit-events';
import {
  ALL_MEDICAL_RECORDS_REQUEST,
  CLAIM_INVOICE_REQUEST,
  MEDICAL_RECORD_REQUEST,
} from './constants/medical-records-types';

export function formatAuditObject(
  audit,
  { isDescription, dateFormat = 'M/D/YYYY' } = {}
) {
  const data = {
    detail: `${audit.initiator} - ${getDateAsFormattedLocalTZ(
      audit.created_at,
      dateFormat
    )}`,
  };

  if (audit.action === RECORD_REQUEST_CREATED) {
    data.event = 'Record request created';
  } else if (audit.action === REQUEST_1_SENT) {
    data.event = 'Request #1 sent';
  } else if (audit.action === REQUEST_2_SENT) {
    data.event = 'Request #2 sent';
  } else if (audit.action === REQUEST_3_SENT) {
    data.event = 'Request #3 sent';
  } else if (audit.action === NO_RESPONSE_FROM_CLINIC) {
    data.event = 'Closed: No response from clinic';
  } else if (audit.action === RECORDS_UNAVAILABLE) {
    const addedInfo = `${audit.reason} ${audit.notes ? `("${audit.notes}")` : ''}`;

    data.event = isDescription
      ? 'Records Unavailable'
      : `Records Unavailable: ${addedInfo}`;
    if (isDescription) data.description = addedInfo;
  } else if (audit.action === RECORDS_RECEIVED) {
    const addedInfo =
      audit.initiator === 'CLINIC'
        ? 'Hospital uploaded files'
        : 'Pumpkin marked records as received';

    data.event = isDescription
      ? 'Records Received'
      : `Records Received: ${addedInfo}`;
    if (isDescription) data.description = addedInfo;
  } else if (audit.action === RECEIPT_SENT) {
    const addedInfo = 'Email receipt sent for the hospital uploaded files';
    data.event = isDescription ? 'Receipt sent' : `Receipt sent: ${addedInfo}`;
    if (isDescription) data.description = addedInfo;
  }

  return data;
}

export function formatRequestedRecordsText(records) {
  // first sort the documents
  const typePriority = {
    [ALL_MEDICAL_RECORDS_REQUEST]: 0,
    [MEDICAL_RECORD_REQUEST]: 1,
    [CLAIM_INVOICE_REQUEST]: 2,
  };

  const sortedRecords = records.sort((a, b) => {
    const typeA = a.type;
    const typeB = b.type;
    if (typePriority[typeA] < typePriority[typeB]) {
      return -1;
    }
    if (typePriority[typeA] > typePriority[typeB]) {
      return 1;
    }

    if (typeA === MEDICAL_RECORD_REQUEST) {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      return dateA - dateB;
    }

    return 0;
  });

  return sortedRecords.map(record => {
    const returnRecord = { ...record };
    if (record.type === ALL_MEDICAL_RECORDS_REQUEST) {
      returnRecord.text = record.start_date
        ? `All medical records from ${parseAndFormatDate(
            record.start_date,
            'M/D/YYYY'
          )} to present`
        : 'All medical records';
    }
    if (record.type === CLAIM_INVOICE_REQUEST) {
      returnRecord.text = `${parseAndFormatDate(
        record.start_date,
        'M/D/YYYY'
      )}: Paid Invoice`;
    }
    if (record.type === MEDICAL_RECORD_REQUEST) {
      returnRecord.text = `${parseAndFormatDate(record.start_date, 'M/D/YYYY')}: ${
        record.description
      }`;
    }

    // say this 3 times fast
    return returnRecord;
  });
}
