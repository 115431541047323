import { formatISODate } from '../shared/utils';

export function policyOptionsWithStatusForSelectedPet(pet) {
  const petPolicies = pet.policies || [];
  return petPolicies.map(policy => {
    const formattedEffectiveDate = formatISODate(policy.policy_effective_date, {
      isGenerated: true,
    });
    return {
      id: policy.id,
      name: `${formattedEffectiveDate} - ${policy.status}`,
      formattedEffectiveDate,
    };
  });
}
