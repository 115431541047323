export const routes = {
  auditLogs: 'audit-logs',
  claims: 'claims',
  claimsLibrary: 'claims-library',
  claimsSumary: 'claims-summary',
  claimattachments: 'claim-attachments',
  diagnosis: 'diagnosis',
  reimbursements: 'reimbursements',
  mgaEmails: 'users/emails',
  emails: 'emails',
  invoices: 'invoices',
  notes: 'notes',
  opsUsers: 'ops-users',
  organizations: 'organizations',
  pets: 'pets',
  petPlans: 'pet-plans',
  plans: 'plans',
  policies: 'policies',
  practices: 'practices',
  products: 'products',
  quotes: 'quotes',
  quotesUser: 'quotes/user',
  shipments: 'shipments',
  customers: 'customers',
  questionnaires: 'questionnaires',
  refunds: 'refunds',
  addresses: 'addresses',
  zipcode: 'utils/zipcode',
  vets: 'vets',
  user: 'user',
  discounts: 'discounts',
  documents: 'documents',
  documentsV2: 'documents-v2',
  cancellationsDashboard: 'cancellation-assignments',
  cancellationDetails: 'cancellation-assignments/details',
  cancellations: 'cancellations',
  addons: 'addons',
  denialReason: 'denial-reason',
  rules: 'run-rules',
  removePendingLines: 'run-rules/remove-pending-lines',
  storeDecisions: 'run-rules/store-decisions',
  calculateUpdateClaim: 'run-rules/calculate-update-claim',
  dashboard: 'dashboard',
  medicalRecordsRequests: 'medical-records-requests',
  wellness: 'wellness',
  wellnessQuote: 'wellness-quotes',
  insurance: 'insurance',
  allQuotes: 'all-quotes',
  calculateGroupDiscount: 'calculate-group-discount',
  addGroupDiscount: 'add-group-discount',
};

export default routes;
