import { formatISODate } from '../shared/utils';

export function wellnessOptionsWithStatusForSelectedPet(pet) {
  const formattedEffectiveDate = formatISODate(pet.wellness?.coverage_start_date, {
    isGenerated: true,
  });
  return [
    {
      id: pet.wellness?.id,
      name: `${formattedEffectiveDate} - ${pet.wellness?.status}`,
      formattedEffectiveDate,
    },
  ];
}
