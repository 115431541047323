import { memo } from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

import sanitizeRestProps from '../lib/sanitizeRestProps';

/**
 * The UrlField provided by React Admin doesn't allow us to
 * override the text of the link. It just uses the provided
 * href as the link text.
 *
 * This component is basically a clone of React Admin's UrlField
 * with the capability to provide a linkText property.
 *
 */
function _CustomUrlField(props) {
  const { className, source, linkText, rawLink, ...rest } = props;
  const record = useRecordContext();
  const href = record?.[source] ?? rawLink;

  return (
    <a className={className} href={href} {...sanitizeRestProps(rest)}>
      {linkText || href}
    </a>
  );
}

_CustomUrlField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  className: PropTypes.string,
  cellClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  record: PropTypes.object,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  rawLink: PropTypes.string,
};

const CustomUrlField = memo(_CustomUrlField);

export default CustomUrlField;
