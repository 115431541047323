import { useState } from 'react';
import {
  AutocompleteInput,
  Form,
  required,
  useNotify,
  useUpdate,
} from 'react-admin';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { usePetBreedsBySpecies } from '@pumpkincare/pets';

import RoutePaths from '../../../../routes';
import { editPetModalStyle } from './edit-pet-modal-style';

function EditPetModal({ record, onClose, onSuccess }) {
  const classes = editPetModalStyle();

  const [update, { isLoading: isUpdateLoading }] = useUpdate();
  const notify = useNotify();
  const { data: breedList } = usePetBreedsBySpecies(`${record.species}s`);

  const [breedData, setBreedData] = useState({});
  const [gender, setGender] = useState('');

  function handleSubmitForm() {
    const payload = {
      name: record.name,
      gender: gender,
      age: record.age,
      breed_name: breedData.name,
      breed_type: breedData.type,
      breed_code: breedData.code,
    };

    update(
      RoutePaths.pets,
      { data: payload, id: record.id },
      {
        onSuccess: data => {
          onSuccess(data);
        },
        onError: error => {
          notify(
            `There was an error while updating the pet details: ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      }
    );
  }

  function handleOnCancel() {
    onClose();
  }

  return (
    <Dialog
      open
      onClose={handleOnCancel}
      className={classes.root}
      classes={{ paper: classes.paper }}
    >
      <Form onSubmit={handleSubmitForm}>
        <DialogTitle className={classes.modalTitle}>
          {`Add Details for ${record.name}’s Quote`}
        </DialogTitle>

        <Divider />

        <DialogContent className={classes.modalContent}>
          <div className={classes.breedCode}>
            <AutocompleteInput
              name='breed_code'
              source={'breed_code'}
              label='Breed'
              choices={breedList}
              optionText='name'
              optionValue='code'
              validate={required()}
              translateChoice={false}
              fullWidth
              variant='outlined'
              size={'big'}
              className={classes.autoComplete}
              onChange={(event, value) =>
                value ? setBreedData(value) : setBreedData({})
              }
              options={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </div>

          <Typography style={{ margin: '4px 0 0' }}>
            Gender<sup className={classes.asterisk}>*</sup>
          </Typography>

          <FormControl component='fieldset'>
            <RadioGroup
              row
              sx={{ marginLeft: '16px', gap: '100px' }}
              value={gender}
              onChange={e => setGender(e.target.value)}
            >
              <FormControlLabel
                value={'M'}
                control={<Radio color='primary' />}
                label='Male'
              />

              <FormControlLabel
                value={'F'}
                control={<Radio color='primary' />}
                label='Female'
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>

        <DialogActions className={classes.modalActions}>
          <Button onClick={handleOnCancel} variant='outlined'>
            CANCEL
          </Button>

          <Button
            variant='contained'
            color='primary'
            disabled={isUpdateLoading || !gender || !Object.keys(breedData).length}
            type={'submit'}
          >
            CONTINUE
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

EditPetModal.propTypes = {
  record: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default EditPetModal;
