import {
  CreateButton,
  Datagrid,
  Filter,
  ListBase,
  ListToolbar,
  Pagination,
  ReferenceManyField,
  SelectInput,
  TextField,
  useRecordContext,
} from 'react-admin';
import { Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  useInvoicePetNamesByUserId,
  useInvoicePolicyYearsByUserId,
} from '@pumpkincare/users';

import { PERMISSION_CREATE_CUSTOMER_INVOICE } from '../../../../auth/permissions';
import ArrayTextField from '../../../../components/ArrayTextField';
import UserInfoCard from '../../../../components/customers/UserInfoCard';
import DateFieldIgnoringZone from '../../../../components/DateFieldIgnoringZone';
import GridLayout from '../../../../components/GridLayout';
import { MoneyField } from '../../../../components/MoneyField';
import ResourceGrid from '../../../../components/ResourceGrid';
import RestrictedAction from '../../../../components/security/RestrictedAction';
import StatusChip from '../../../../components/StatusChip';
import { useInvoicesByUserId } from '../../../../lib/users';
import RoutePaths from '../../../../routes';
import CustomerInvoiceShow from './../CustomerInvoiceShow';
import CustomerBillingDetails from './CustomerBillingDetails';
import { customerBillingStyle } from './CustomerBillingStyle';

const thisYear = moment().utc().startOf('year').format('YYYY-MM-DD');
const lastThreeMonths = moment()
  .utc()
  .startOf('day')
  .subtract(3, 'months')
  .format('YYYY-MM-DD');
const thisMonth = moment().utc().startOf('month').format('YYYY-MM-DD');

function DataFilter({ props, petNamesChoices, petPoliciesYear, invoices = [] }) {
  const dateChoices = [
    { id: thisYear, name: 'This Year' },
    { id: lastThreeMonths, name: 'Last 3 Months' },
    { id: thisMonth, name: 'This Month' },
  ];

  if (petPoliciesYear?.length > 0) {
    dateChoices.push({
      id: petPoliciesYear[0].policy_year,
      name: 'This policy year',
    });
  }

  const productNames = invoices.flatMap(item => item.product_names);
  const uniqueProductNames = [...new Set(productNames)];

  const typeChoices = uniqueProductNames.map(name => ({
    id: name,
    name: name,
  }));

  return (
    <Filter {...props}>
      <SelectInput
        label='Date'
        source='created_at_gte'
        choices={dateChoices}
        allowEmpty={true}
        emptyText={'Anytime'}
        alwaysOn
        variant={'outlined'}
      />
      <SelectInput
        label='Pet Names'
        source='pet_names'
        choices={petNamesChoices}
        allowEmpty={true}
        emptyText={'All names'}
        alwaysOn
        variant={'outlined'}
      />
      <SelectInput
        label='Type'
        source='pet_products'
        choices={typeChoices}
        allowEmpty={true}
        emptyText={'All types'}
        alwaysOn
        variant={'outlined'}
      />
    </Filter>
  );
}

DataFilter.propTypes = {
  props: PropTypes.object,
  petNamesChoices: PropTypes.arrayOf(PropTypes.object),
  petPoliciesYear: PropTypes.arrayOf(PropTypes.object),
  invoices: PropTypes.arrayOf(PropTypes.object),
};

export default function CustomerBilling(props) {
  const userRecord = useRecordContext();
  const { id: userId } = userRecord;
  const { data: invoiceData } = useInvoicesByUserId(userId);
  const classes = customerBillingStyle();
  const { data: petNamesChoices = [] } = useInvoicePetNamesByUserId(userId);

  const { data: petPoliciesYear = [{ policy_year: thisYear }] } =
    useInvoicePolicyYearsByUserId();

  return (
    <GridLayout {...props}>
      <ResourceGrid container direction='column' spacing={2}>
        <Typography className={[classes.title, classes.titleIndented].join(' ')}>
          Customer Information
        </Typography>
        <ResourceGrid style={{ paddingLeft: '0px' }} item>
          <UserInfoCard {...props} />
        </ResourceGrid>
        <CustomerBillingDetails classes={classes} />
        <ResourceGrid item>
          <ReferenceManyField
            label=''
            reference={RoutePaths.invoices}
            target='user_id'
            filter={{ user_id: userId }}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={50}
          >
            <ListBase
              {...props}
              perPage={50}
              filter={{ user_id: userId }}
              sort={{ field: 'created_at', order: 'DESC' }}
              disableSyncWithLocation
            >
              <div className={classes.invoices}>
                <Typography
                  className={[classes.title, classes.titleToLeft].join(' ')}
                >
                  Invoices
                </Typography>
                <ListToolbar
                  filters={
                    <DataFilter
                      petNamesChoices={petNamesChoices}
                      petPoliciesYear={petPoliciesYear}
                      invoices={invoiceData}
                    />
                  }
                />
              </div>
              <div className={classes.tableWidth}>
                <Datagrid
                  rowClick='expand'
                  bulkActionButtons={false}
                  expand={<CustomerInvoiceShow showWarning={true} sx='850px' />}
                >
                  <ArrayTextField
                    label={
                      <Typography className={classes.label}>PET NAMES</Typography>
                    }
                  />
                  <TextField
                    label={
                      <Typography className={classes.label}>INVOICE ID</Typography>
                    }
                    source='invoice_number'
                  />
                  <MoneyField
                    label={<Typography className={classes.label}>TOTAL</Typography>}
                    source='total'
                  />
                  <DateFieldIgnoringZone
                    label={<Typography className={classes.label}>DATE</Typography>}
                    source='created_at'
                  />
                  <StatusChip />
                  <MoneyField
                    className={classes.redText}
                    source='total_refund'
                    label={
                      <Typography className={classes.label}>
                        TOTAL REFUNDED
                      </Typography>
                    }
                  />
                </Datagrid>
                <Pagination />
              </div>
            </ListBase>
          </ReferenceManyField>
        </ResourceGrid>
        <ResourceGrid style={{ marginTop: '8px' }} container spacing={2}>
          <RestrictedAction requiredPermissions={PERMISSION_CREATE_CUSTOMER_INVOICE}>
            <CreateButton
              label='Create New Invoice'
              resource={RoutePaths.invoices}
              state={{ record: { customer: userRecord } }}
            />
          </RestrictedAction>
        </ResourceGrid>
      </ResourceGrid>
    </GridLayout>
  );
}
