import { useEffect, useState } from 'react';
import {
  ArrayField,
  Create,
  Datagrid,
  DateTimeInput,
  FunctionField,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useRecordContext,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { MoneyField } from '../../components/MoneyField';
import NumericInput from '../../lib/shared/ui/numeric-input';
import RoutePaths from '../../routes';
import { CancellationRefund } from '../../shared/components';
import { convertDollarsToCents } from '../../shared/utils/currencyUtils';

function CustomInput({ sumRefunds }) {
  const record = useRecordContext();
  const source = `refund_id_${record.id}`;
  const inputDisable = record.total_refund_to_date === record.amount;

  return (
    <NumericInput
      id={source}
      source={source}
      label=''
      onBlur={e => sumRefunds(e)}
      disabled={inputDisable}
      key={source}
      prefix='$'
      precision={2}
      allowDecimalValue
    />
  );
}

CustomInput.propTypes = {
  sumRefunds: PropTypes.func.isRequired,
};

const RefundCreate = () => {
  const objRefund = {};
  const location = useLocation();

  const { state } = location;
  const refundsRedirect = state.refundsRedirect;

  const userId = state.record.user_id;
  state.record.items.forEach(item => {
    objRefund[`refund_id_${item.id}`] = 0;
  });

  const { showCancellationLink } = state;

  const [isDisabled, setDisable] = useState(false);
  const [inputValue, setInputValue] = useState(objRefund);
  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    const sumValues = Object.values(inputValue).reduce((total, value) => {
      if (value && !Number.isNaN(value)) {
        return total + parseFloat(value) * 100;
      }
      return total + 0;
    }, 0);

    if (sumValues <= 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    setTotalValue(sumValues);
  }, [inputValue]);

  const { user_id } = state.record;
  const { total_refund } = state.record;
  const defaultTime = new Date();
  const redirect = refundsRedirect
    ? refundsRedirect
    : user_id
      ? `/${RoutePaths.customers}/${user_id}/show/billing`
      : 'show';

  const RefundTitle = () => {
    const record = useRecordContext();
    return (
      <span>
        Invoice{' '}
        {record ? `${record.invoice_number}: Submit Refund` : 'Submit Refund'}
      </span>
    );
  };

  const sumRefunds = obj => {
    setInputValue(prevState => ({
      ...prevState,
      [obj.target.name]: obj.target.value,
    }));
  };

  const CustomToolbar = propsRefund => (
    <Toolbar {...propsRefund}>
      <SaveButton
        disabled={isDisabled}
        label='Submit Refund'
        submitOnEnter={false}
      />
    </Toolbar>
  );

  const validateRefundSubmit = formValue => {
    const keys = Object.keys(formValue);
    const values = Object.values(formValue);
    const refundList = [];
    const errors = {};

    keys.forEach((key, index) => {
      if (key.startsWith('refund_id_')) {
        refundList.push({
          invoice_item_id: key.substring(10, key.length),
          refund_amount: convertDollarsToCents(values[index]),
          field_name: key,
        });
      }
    });

    if (refundList.length > 0) {
      for (let i = 0; i < refundList.length; i += 1) {
        for (let j = 0; j < formValue.items.length; j += 1) {
          if (formValue.items[j].id === refundList[i].invoice_item_id) {
            if (refundList[i].refund_amount < 0) {
              errors[refundList[i].field_name] = 'Refund cannot be negative';
              return errors;
            }

            if (refundList[i].refund_amount <= 0 && (totalValue || 0) <= 0) {
              errors[refundList[i].field_name] = 'Refund cannot be 0 or lower';
              return errors;
            }

            if (
              refundList[i].refund_amount +
                (formValue.items[j].total_refund_to_date || 0) >
              formValue.items[j].amount
            ) {
              errors[refundList[i].field_name] =
                'Total refund cannot exceed line item amount';
              return errors;
            }
          }
        }
      }
    }

    return errors;
  };

  return (
    <>
      <Create title={<RefundTitle />} redirect={redirect}>
        <SimpleForm toolbar={<CustomToolbar />} validate={validateRefundSubmit}>
          <TextInput label='Pet Names' source='pet_names' disabled />
          <TextInput label='Invoice Id' source='invoice_number' disabled />
          <DateTimeInput
            label='Date and Time of Refund'
            source='date_refund'
            defaultValue={defaultTime}
            disabled
          />
          <TextInput multiline source='refund_reason' label='Reason for Refund' />
          <ArrayField source='items' label=''>
            <Datagrid bulkActionButtons={false}>
              <TextField source='type' />
              <TextField source='description' />
              <MoneyField source='amount' />
              <MoneyField
                style={{ color: 'red' }}
                label='Refunded to Date'
                source='total_refund_to_date'
              />

              <CustomInput label='New Refund' sumRefunds={sumRefunds} />
            </Datagrid>
            <FunctionField
              render={() => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '307px',
                      marginTop: '8px',
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: '16px',
                        fontWeight: '700',
                        marginRight: '30px',
                      }}
                    >
                      Refund Total
                    </Typography>
                    <MoneyField
                      style={{
                        color: 'red',
                        marginRight: '98px',
                      }}
                      record={{ total_refund: total_refund }}
                      source='total_refund'
                    />
                    <MoneyField
                      style={{
                        color: 'red',
                      }}
                      record={{ totalValue: totalValue }}
                      source='totalValue'
                    />
                  </div>
                );
              }}
            />{' '}
          </ArrayField>
        </SimpleForm>
      </Create>
      {showCancellationLink ? <CancellationRefund userId={userId} /> : null}
    </>
  );
};

export default RefundCreate;
