import makeStyles from '@mui/styles/makeStyles';

const addPaymentMethodModalStyle = makeStyles(
  {
    root: {
      backgroundColor: 'rgb(0, 125, 255, 0.08)',
    },
    paper: { backgroundColor: '#FAFAFA', maxWidth: '604px', width: '83vw' },
    modalTitle: {
      fontSize: '20px',
      lineHeight: '32px',
      color: '#2A41DE',
      fontWeight: '500',
    },

    modalContent: {
      padding: '16px 32px',
      display: 'flex',
      flexDirection: 'column',
      gap: '0 48px',
    },

    modalActions: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '0 16px',
      padding: '0px 32px 24px',
    },

    paymentForm: {
      marginTop: '0px !important',
    },
  },
  { name: 'add-payment-method-modal' }
);

export { addPaymentMethodModalStyle };
