import React, { useState } from 'react';
import {
  SimpleForm,
  TextInput,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import ApprovalIcon from '@mui/icons-material/Approval';
import CalculateIcon from '@mui/icons-material/Calculate';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment/moment';

import { useOpsUser, usePetByUser } from '@pumpkincare/users';

import RoutePaths from '../../../../routes';
import { addGroupDiscount, calculateGroupDiscount } from '../../../organizations';
import FieldStatusChip from '../../../pets/pet-show/field-status-chip/field-status-chip';
import petShowStyle from '../../../pets/pet-show/pet-show-style';

const VALID_STATUSES = ['renew_pending', 'active', 'lapsed'];

function Discounts() {
  const { id } = useParams();
  const record = useRecordContext();

  const redirectTo = useRedirect();
  const notify = useNotify();

  const [groupDiscounts, setGroupDiscounts] = useState([]);
  const [passcode, setPasscode] = useState('');
  const [selectedPets, setSelectedPets] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const { data: currentOpsUserData, isLoading: isOpsUserLoading } = useOpsUser();
  const { data: petData, isLoading } = usePetByUser(id, {
    onSuccess: data => formatPetsRows(data, groupDiscounts),
  });

  const [updatedPetData, setUpdatedPetData] = useState(petData);

  const classes = petShowStyle();

  function formatPetsRows(petData, groupDiscounts) {
    if (petData) {
      const data = petData.data || petData;
      const filteredPetData = data.filter(pet =>
        pet.policies.some(policy => VALID_STATUSES.includes(policy.status))
      );
      const updatedData = filteredPetData.map(pet => {
        const discount = groupDiscounts.find(d => d.pet_id === pet.id);
        return discount ? { ...pet, ...discount } : pet;
      });
      const sortedData = updatedData.sort((a, b) => {
        const aHasDiscount = a.policies.some(
          policy => policy.group_discount_percentage !== null
        );
        const bHasDiscount = b.policies.some(
          policy => policy.group_discount_percentage !== null
        );
        return aHasDiscount - bHasDiscount;
      });
      setUpdatedPetData(sortedData);
      setExpandedRows(sortedData.map((_, index) => index));
    }
  }

  if (isLoading || isOpsUserLoading) {
    return <CircularProgress />;
  }

  function handleSelectPet(petName, petId) {
    setSelectedPets(prevSelectedPets => {
      const isSelected = prevSelectedPets.includes(petName);
      const newSelectedPets = isSelected
        ? prevSelectedPets.filter(name => name !== petName)
        : [...prevSelectedPets, petName];

      if (isSelected) {
        setGroupDiscounts(prevGroupDiscounts =>
          prevGroupDiscounts.filter(discount => discount.pet_id !== petId)
        );
        formatPetsRows(petData, groupDiscounts);
      }

      return newSelectedPets;
    });
  }

  function handleSelectAll() {
    const availablePets = updatedPetData.filter(
      pet =>
        pet.policies.every(policy => policy.group_discount_percentage === null) &&
        pet.status_type !== 'ERROR'
    );
    if (selectedPets.length === availablePets.length) {
      setSelectedPets([]);
      setGroupDiscounts([]);
    } else {
      setSelectedPets(availablePets.map(pet => pet.name));
    }
  }

  function handleExpandRow(index) {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter(row => row !== index)
        : [...prevExpandedRows, index]
    );
  }

  function formatDate(value, format = 'MM/DD/YYYY') {
    return value ? moment.parseZone(value).format(format) : '';
  }

  function handleGroupDiscountClick() {
    if (selectedPets.length === 0) {
      notify('No pets were selected to calculate the discount', {
        type: 'warning',
      });
      return;
    }
    calculateGroupDiscount({
      data: {
        user_id: record.id,
        customer_pkn: record.pumpkin_id,
        discount_passcode: passcode,
        pets: selectedPets,
      },
    })
      .then(response => {
        if (!response.data?.length) {
          notify('There is no discount to be applied to the customer pets.', {
            type: 'warning',
          });
        }
        setGroupDiscounts(response.data);
        formatPetsRows(petData, response.data);
      })
      .catch(error => {
        notify(error.message, {
          type: 'warning',
        });
      });
  }

  function handleAddDiscountClick() {
    const groupDiscountsFinal = groupDiscounts
      .filter(pet => !['ERROR', 'WARNING'].includes(pet.status_type))
      .map(item => ({
        user_id: currentOpsUserData.id,
        pet_id: item.pet_id,
        policy_id: item.policy_id,
        organization_id: item.organization_id,
        group_discount: item.group_discount,
        group_discount_percentage: item.group_discount_percentage,
        new_filed_rate: item.new_filed_rate,
        new_premium: item.new_premium,
        new_capped_rate: item.new_capped_rate,
      }));
    if (groupDiscountsFinal.length === 0 || selectedPets.length === 0) {
      notify('Select at least one pet to apply a group discount.', {
        type: 'warning',
      });
      return;
    }

    addGroupDiscount(groupDiscountsFinal)
      .then(response => {
        if (!response.error) {
          notify(response.message, { type: 'success' });
          redirectTo('show', `${RoutePaths.customers}/${id}/show`);
        } else {
          notify(response.message, { type: 'warning' });
        }
      })
      .catch(error => {
        notify(error.message, {
          type: 'warning',
        });
      });
  }
  return (
    <SimpleForm toolbar={null} onSubmit={null}>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={3}
        sx={{ marginBottom: '10px' }}
      >
        <Grid item>
          <TextInput
            label='Passcode'
            source='passcode'
            value={passcode}
            onChange={event => setPasscode(event.target.value)}
          />
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={3}
      >
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            sx={{ height: '500px', overflowY: 'auto' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      indeterminate={
                        selectedPets.length > 0 &&
                        selectedPets.length < updatedPetData?.length
                      }
                      checked={
                        updatedPetData?.length > 0 &&
                        selectedPets.length === updatedPetData?.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Pet Name</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {updatedPetData?.map((pet, index) => (
                  <React.Fragment key={pet.id}>
                    {/* eslint-disable-next-line prettier/prettier */}
                    <TableRow
                      sx={{
                        backgroundColor:
                          pet.status_type === 'ERROR' ? '#FEEFF1' : 'inherit',
                      }}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          checked={
                            pet.status_type === 'ERROR' ||
                            pet.policies.some(
                              policy => policy.group_discount_percentage !== null
                            )
                              ? false
                              : selectedPets.includes(pet.name)
                          }
                          onChange={() => handleSelectPet(pet.name, pet.id)}
                          disabled={
                            pet.status_type === 'ERROR' ||
                            pet.policies.some(
                              policy => policy.group_discount_percentage !== null
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {pet.name}
                        {pet.policies.some(
                          policy => policy.group_discount_percentage !== null
                        ) && (
                          <Typography sx={{ color: '#FA4154', fontSize: '12px' }}>
                            A group discount has already been applied to this pet:
                            <strong> {pet.policies[0].organization_passcode}</strong>
                          </Typography>
                        )}
                        {pet.status_type === 'ERROR' && (
                          <Typography sx={{ color: '#FA4154', fontSize: '12px' }}>
                            {pet.error_message}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <IconButton
                          onClick={() => handleExpandRow(index)}
                          aria-expanded={expandedRows.includes(index)}
                          aria-label='expand row'
                        >
                          {expandedRows.includes(index) ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={3}
                      >
                        <Collapse
                          in={expandedRows.includes(index)}
                          timeout='auto'
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table size='small'>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ borderBottom: 'none' }}>
                                    Status
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 'none' }}>
                                    Year
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 'none' }}>
                                    Effective Date
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 'none' }}>
                                    Program ID
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 'none' }}>
                                    Premium
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 'none' }}>
                                    Group Discount
                                  </TableCell>
                                  <TableCell sx={{ borderBottom: 'none' }}>
                                    New Premium
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {pet.policies
                                  .filter(policy =>
                                    VALID_STATUSES.includes(policy.status)
                                  )
                                  .sort((a, b) => b.year - a.year)
                                  .map(policy => {
                                    const discount = groupDiscounts.find(
                                      d =>
                                        d.pet_id === pet.id &&
                                        d.policy_id === policy.id
                                    );
                                    return (
                                      <TableRow key={policy.id}>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                          <FieldStatusChip
                                            source='status'
                                            headerClassName={classes.colHeader}
                                            record={{ status: policy.status }}
                                          />
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                          {policy.year}
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                          {formatDate(policy.policy_effective_date)}
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                          {policy.program_id}
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                          ${policy.premium}
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                          -$
                                          {Math.abs(
                                            (discount?.group_discount ?? 0.0) !==
                                              0.0 &&
                                              discount?.status_type !== 'ERROR'
                                              ? discount?.group_discount
                                              : policy.group_discount
                                          ).toFixed(2)}
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none' }}>
                                          $
                                          {discount?.status_type !== 'ERROR'
                                            ? discount?.new_premium ?? '0.00'
                                            : '0.00'}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                              {/* eslint-disable-next-line prettier/prettier */}
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    {/* eslint-disable-next-line prettier/prettier */}
                  </React.Fragment>
                ))}
              </TableBody>
              {/* eslint-disable-next-line prettier/prettier */}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        spacing={3}
        sx={{ marginTop: '10px' }}
      >
        <Grid item>
          <Button
            variant='contained'
            color='secondary'
            label='Calculate'
            disabled={passcode === ''}
            onClick={handleGroupDiscountClick}
            startIcon={<CalculateIcon />}
          >
            Calculate
          </Button>
        </Grid>
        {/* eslint-disable-next-line prettier/prettier */}
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            label='Apply'
            disabled={
              !groupDiscounts.filter(pet => pet.status_type === 'SUCCESS').length
            }
            onClick={handleAddDiscountClick}
            startIcon={<ApprovalIcon />}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </SimpleForm>
  );
}

export default Discounts;
