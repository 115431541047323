import { Grid, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';

import AddressInput from './AddressInput';
import { paymentFormStyle } from './PaymentFormStyle';

function BillingForm(props) {
  const {
    quote,
    onAddressChange,
    isBillingSameAsShipping,
    onSameAsShippingChange,
    shippingAddress = '',
  } = props;

  const styles = paymentFormStyle();

  const billingAddressDefault = quote.existingUserDetails
    ? quote.existingUserDetails.billing_address
    : { country: 'US' };

  return (
    <div>
      <Typography style={{ margin: '24px 0 0' }}>
        Billing address<sup className={styles.asterisk}>*</sup>
      </Typography>

      <FormControl component='fieldset'>
        <RadioGroup
          onChange={onSameAsShippingChange}
          value={isBillingSameAsShipping}
        >
          <FormControlLabel
            value={true}
            control={<Radio color='primary' />}
            label={
              <Grid container direction='column'>
                <span>Same as shipping address</span>
                {shippingAddress ? (
                  <span
                    style={{
                      color: '#6B717E',
                      fontSize: '14px',
                      lineHeight: '14px',
                    }}
                  >
                    {shippingAddress}
                  </span>
                ) : null}
              </Grid>
            }
          />
          <FormControlLabel
            value={false}
            control={<Radio data-testid={'radio-button-false'} color='primary' />}
            label='Use a different billing address'
          />
        </RadioGroup>
      </FormControl>

      {isBillingSameAsShipping === false ? (
        <AddressInput
          quote={quote}
          onChange={onAddressChange}
          style={{ marginTop: '16px' }}
          defaultAddress={billingAddressDefault}
        />
      ) : null}
    </div>
  );
}

BillingForm.defaultProps = {
  quote: {},
  onAddressChange: () => {},
  isBillingSameAsShipping: true,
  onSameAsShippingChange: () => {},
  shippingAddress: '',
};

BillingForm.propTypes = {
  quote: PropTypes.object,
  onAddressChange: PropTypes.func,
  isBillingSameAsShipping: PropTypes.bool,
  onSameAsShippingChange: PropTypes.func,
  shippingAddress: PropTypes.string,
};

export default BillingForm;
