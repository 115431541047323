import { CREATE } from 'react-admin';

import { dataProvider } from '../../../lib/dataProvider/provider';
import RoutePaths from '../../../routes';

export function calculateGroupDiscount(data) {
  return dataProvider(
    CREATE,
    `${RoutePaths.organizations}/${RoutePaths.calculateGroupDiscount}`,
    {
      ...data,
      returnJson: 'BODY',
    }
  ).then(response => response.data);
}

export function addGroupDiscount(data) {
  return dataProvider(
    CREATE,
    `${RoutePaths.organizations}/${RoutePaths.addGroupDiscount}`,
    {
      data,
      returnJson: 'RAW',
    }
  ).then(response => response.data);
}
