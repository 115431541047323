import {
  ArrayField,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Labeled,
  Show,
  TextField,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useShowController,
  useUpdate,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useCancellations } from '@pumpkincare/cancellations';
import { PaperlessSettingsModal } from '@pumpkincare/shared';
import { useInvoicesByUserId } from '@pumpkincare/users';

import ACHStatus from '../../../../components/ACHStatus';
import ACHType from '../../../../components/ACHType';
import CustomUrlField from '../../../../components/CustomUrlField';
import Email from '../../../../components/Email';
import mailMetadata from '../../../../components/Email/metadata';
import useBooleanInput from '../../../../hooks/useBooleanInput';
import RoutePaths from '../../../../routes';
import {
  areAllPetsGroupEnrolled,
  areAllPetsWellnessOnly,
} from '../../../../shared/utils/petUtils';
import PetList from '../pet-list';
import {
  customerDetailStyle,
  sxCustomerDetailStyle,
} from './customer-detail-styles';

function CustomerDetail({
  fundingSourceData,
  petListData = [],
  isPetDataLoading = false,
  id: user_id,
}) {
  const { punks1928WellnessEmployerBenefit, fer4887AddGroupDiscounts } = useFlags();
  const record = useRecordContext();
  const redirect = useRedirect();
  const { record: customerRecord = {} } = useShowController();
  const [update, { isLoading: isSaving }] = useUpdate();
  const { data: cancellations = [] } = useCancellations();
  const { data: userInvoices = [] } = useInvoicesByUserId(user_id);
  const refresh = useRefresh();
  const notify = useNotify();

  const [isPaperlessSettingsModalOpen, togglePaperlessSettingsModal] =
    useBooleanInput(false);

  const areAllWellnessAndGroupEnrolled =
    punks1928WellnessEmployerBenefit &&
    areAllPetsWellnessOnly(petListData) &&
    areAllPetsGroupEnrolled(petListData);

  const showDiscountOption =
    fer4887AddGroupDiscounts && !areAllPetsWellnessOnly(petListData);

  const discountLink = `/${RoutePaths.customers}/${user_id}/show/${RoutePaths.discounts}`;
  function handleSavePaperlessClick(isPaperless) {
    update(
      'users',
      {
        meta: { method: 'PATCH' },
        id: `paperless/${user_id}`,
        data: { is_paperless: isPaperless },
      },
      {
        onSuccess: () => {
          togglePaperlessSettingsModal();
          refresh();
          notify(`Paperless settings updated`, {
            type: 'success',
          });
        },
        onError: error => {
          notify(`There was an error while setting paperless: ${error.message}`, {
            type: 'error',
          });
        },
      }
    );
  }

  const classes = customerDetailStyle();

  return (
    <Show
      actions={null}
      title=' '
      sx={{
        '& .RaShow-card': {
          boxShadow: 'none',
        },
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Typography
          variant='h6'
          sx={{
            color: 'black',
            fontWeight: '500',
          }}
        >
          Customer Information
        </Typography>
        <EditButton
          sx={{
            '&.RaEditButton-root': {
              color: 'rgba(0, 0, 0, 0.56)',
              padding: '0 8px',
              display: 'block',
              minWidth: 0,
              marginLeft: '8px',

              '& .MuiButton-startIcon': {
                margin: '0',
              },
            },
          }}
          label=''
          resource='customers'
          record={record}
        />
      </div>
      {punks1928WellnessEmployerBenefit && record.third_party_entity_name ? (
        <Alert
          icon={<InfoOutlinedIcon sx={{ color: '#0288d1' }} fontSize='inherit' />}
          className={classes.thirdPartyAlert}
        >
          <Typography variant='body2' style={{ color: '#373f51' }}>
            Wellness Plan(s) Managed by{' '}
            {record.enrollment_type === 'employer'
              ? 'Employer'
              : 'Third Party Vendor'}
            : {record.third_party_entity_name}
          </Typography>
        </Alert>
      ) : null}
      <Grid container direction='row' alignItems='flex-start'>
        <Grid xs={2.75} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='FIRST NAME'
          >
            <TextField sx={{ color: 'black' }} source='first_name' variant='body1' />
          </Labeled>
        </Grid>
        <Grid xs={2.75} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='LAST NAME'
          >
            <TextField sx={{ color: 'black' }} source='last_name' variant='body1' />
          </Labeled>
        </Grid>
        <Grid xs={2} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='PKN NUMBER'
          >
            <FunctionField
              label='ID'
              variant='body1'
              render={(record = {}) => {
                return (
                  <CopyToClipboard
                    text={record.pumpkin_id}
                    onCopy={() => {
                      notify(`${record.pumpkin_id} copied`, {});
                    }}
                  >
                    <span style={{ cursor: 'pointer' }}>{record.pumpkin_id}</span>
                  </CopyToClipboard>
                );
              }}
            />
          </Labeled>
        </Grid>
        <Grid xs={2.25} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label={
              punks1928WellnessEmployerBenefit && record.third_party_unique_id
                ? 'THIRD PARTY CUST ID'
                : 'WHITE LABEL ACCOUNT ID'
            }
          >
            {punks1928WellnessEmployerBenefit && record.third_party_unique_id ? (
              <FunctionField
                render={(record = {}) => {
                  return (
                    <div className={classes.thirdParty}>
                      <TextField
                        sx={{ color: 'black' }}
                        record={record}
                        source='third_party_unique_id'
                        variant='body1'
                      />
                      <Tooltip
                        placement='bottom'
                        title={`Wellness ${
                          record.enrollment_type === 'employer'
                            ? 'plan(s) managed by employer'
                            : 'managed by vendor'
                        }: ${record.third_party_entity_name}`}
                      >
                        <InfoOutlinedIcon
                          className={classes.thirdPartyIcon}
                          fontSize='inherit'
                        />
                      </Tooltip>
                    </div>
                  );
                }}
              />
            ) : (
              <TextField
                sx={{ color: 'black' }}
                source='zoetis_sap_id'
                variant='body1'
              />
            )}
          </Labeled>
        </Grid>
        <Grid xs={1.75} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='PHONE NUMBER'
          >
            <TextField sx={{ color: 'black' }} source='phone' variant='body1' />
          </Labeled>
        </Grid>
      </Grid>
      <Grid container direction='row' mt={3} alignItems='flex-start'>
        <Grid xs={2.75} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='BILLING FREQUENCY'
          >
            <FunctionField
              variant='body1'
              sx={{ color: 'black' }}
              render={(record = {}) => {
                return record.is_charged_annually ? 'Annual' : 'Monthly';
              }}
            />
          </Labeled>
        </Grid>
        {showDiscountOption ? (
          <Grid xs={2.75} item>
            <Labeled
              sx={{
                '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
              }}
              label='DISCOUNT CODES'
            >
              <CustomUrlField
                linkText='See Discounts'
                rawLink={discountLink}
                rel='noopener noreferrer'
                className={classes.linkStyle}
              />
            </Labeled>
          </Grid>
        ) : null}
        <Grid xs={showDiscountOption ? 2 : 2.75} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='PAPERLESS SETTING'
          >
            <FunctionField
              variant='body1'
              sx={{ color: 'black' }}
              render={(record = {}) => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      {record.is_paperless ? 'Enrolled' : 'Not Enrolled'}
                    </Typography>
                    <IconButton
                      sx={{
                        '&.MuiIconButton-root': {
                          color: 'rgba(0, 0, 0, 0.56)',
                          padding: '0px 8px',
                        },
                      }}
                      onClick={togglePaperlessSettingsModal}
                      size='large'
                    >
                      <CreateIcon style={{ width: '18px' }} />
                    </IconButton>
                  </div>
                );
              }}
            />
          </Labeled>
        </Grid>
        <Grid xs={showDiscountOption ? 2.25 : 2} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='GLADLY LINK'
          >
            <CustomUrlField
              linkText='Open in Gladly'
              source='gladly_customer_url'
              target='_blank'
              rel='noopener noreferrer'
              className={classes.linkStyle}
            />
          </Labeled>
        </Grid>
        <Grid xs={showDiscountOption ? 1.75 : 4.5} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='EMAIL'
          >
            <FunctionField
              label='ID'
              variant='body1'
              render={(record = {}) => {
                return (
                  <CopyToClipboard
                    text={record.email}
                    onCopy={() => {
                      notify(`${record.email} copied`, {});
                    }}
                  >
                    <span style={{ cursor: 'pointer' }}>{record.email}</span>
                  </CopyToClipboard>
                );
              }}
            />
          </Labeled>
        </Grid>
      </Grid>

      <Typography
        variant='subtitle2'
        sx={{ color: 'black', marginTop: '32px', marginBottom: '18px' }}
      >
        Addresses
      </Typography>

      <ArrayField
        source='new_addresses'
        record={{
          new_addresses: [
            {
              type: 'rating',
              ...customerRecord.rating_address,
              user_name: `${customerRecord.first_name} ${customerRecord.last_name}`,
              user_pumpkin_id: customerRecord.pumpkin_id,
            },
            {
              type: 'mailing',
              ...customerRecord.mailing_address,
              user_name: `${customerRecord.first_name} ${customerRecord.last_name}`,
              user_pumpkin_id: customerRecord.pumpkin_id,
            },
          ],
        }}
      >
        <Datagrid
          sx={{
            '& .RaDatagrid-headerCell': sxCustomerDetailStyle.headerCell,
            '& .RaDatagrid-table': sxCustomerDetailStyle.table,
            '& .RaDatagrid-rowCell': sxCustomerDetailStyle.rowCell,
          }}
          bulkActionButtons={false}
        >
          <FunctionField
            label='TYPE'
            render={(record = {}) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant='body1'
                    style={{
                      marginRight: '4px',
                      display: 'inline-block',
                      textTransform: 'capitalize',
                    }}
                  >
                    {record.type}
                  </Typography>

                  <Tooltip
                    placement='left'
                    title={
                      record.type === 'mailing'
                        ? 'where the customer receives physical mail'
                        : 'where the pets live'
                    }
                  >
                    <HelpOutlineIcon style={{ width: '14px', height: 'auto' }} />
                  </Tooltip>
                </div>
              );
            }}
          />

          <TextField variant='body1' label='STREET 1' source='street_1' />
          <TextField variant='body1' label='STREET 2' source='street_2' />
          <TextField variant='body1' label='CITY' source='city' />
          <TextField variant='body1' label='STATE' source='state_province' />
          <TextField variant='body1' label='ZIPCODE' source='zipcode' />
          <FunctionField
            render={(record = {}) => (
              <Button
                onClick={() => {
                  redirect('create', '/addresses', {}, {}, record);
                }}
              >
                <EditIcon style={{ color: 'rgba(0, 0, 0, 0.56)' }} />
              </Button>
            )}
          />
        </Datagrid>
      </ArrayField>

      <Typography variant='subtitle2' sx={{ color: 'black', marginTop: '42px' }}>
        Reimbursement Method
      </Typography>
      <Grid container direction='row' alignItems='flex-start'>
        <Grid xs={2.75} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='TYPE'
          >
            {fundingSourceData?.length ? (
              <ACHType
                sx={{ color: 'black' }}
                variant='body1'
                fundingSource={fundingSourceData[0]}
              />
            ) : (
              <Typography sx={{ color: 'black' }} variant='body1'>
                Mailed Check
              </Typography>
            )}
          </Labeled>
        </Grid>
        <Grid xs={2.75} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='STATUS'
          >
            {fundingSourceData?.length ? (
              <ACHStatus variant='body1' fundingSource={fundingSourceData[0]} />
            ) : (
              <Typography />
            )}
          </Labeled>
        </Grid>
        <Grid xs={2} item>
          <Labeled
            sx={{
              '& .RaLabeled-label': sxCustomerDetailStyle.labelCell,
            }}
            label='TIMESTAMP'
          >
            {fundingSourceData?.length ? (
              <DateField
                variant='body1'
                showTime={true}
                record={{
                  response_received: fundingSourceData[0].response_received,
                }}
                source='response_received'
              />
            ) : (
              <Typography />
            )}
          </Labeled>
        </Grid>
        <Grid xs={4.5} item>
          <Email id={user_id} entity='user' settings={mailMetadata.user} />
        </Grid>
      </Grid>

      <Typography
        variant='h6'
        sx={{
          color: 'black',
          marginTop: '32px',
          marginBottom: '24px',
          fontWeight: '500',
        }}
      >
        Pet Information
      </Typography>

      <div className={classes.cancelPets}>
        <Typography variant='body2' sx={{ color: 'black', marginRight: '8px' }}>
          Want to cancel policies, wellness or plans?
        </Typography>
        <CreateButton
          label='Cancel Pets'
          sx={{
            border: `1px solid ${
              areAllWellnessAndGroupEnrolled ? '#0000001F' : 'rgba(83, 104, 245, 1)'
            }`,
          }}
          icon={null}
          resource={RoutePaths.cancellations}
          state={{ record: { customer: record } }}
          disabled={areAllWellnessAndGroupEnrolled}
        />
        {areAllWellnessAndGroupEnrolled ? (
          <Typography variant='body2' sx={{ color: '#6B717E', marginLeft: '8px' }}>
            Customer must contact their employee benefits administrator to cancel
            these plan(s)
          </Typography>
        ) : null}
      </div>

      {isPetDataLoading ? (
        <CircularProgress />
      ) : (
        <PetList
          hasCancellations={cancellations.some(
            cancellation => cancellation.id === user_id
          )}
          userInvoices={userInvoices}
          petListData={petListData}
        />
      )}
      {isPaperlessSettingsModalOpen ? (
        <PaperlessSettingsModal
          isSaving={isSaving}
          onSaveButtonClick={handleSavePaperlessClick}
          emailAddress={customerRecord.email}
          isPaperless={!!customerRecord?.is_paperless}
          onClose={togglePaperlessSettingsModal}
        />
      ) : null}
    </Show>
  );
}

CustomerDetail.propTypes = {
  id: PropTypes.string,
  isPetDataLoading: PropTypes.bool,
  fundingSourceData: PropTypes.arrayOf(
    PropTypes.shape({
      response_received: PropTypes.string,
    })
  ),
  petListData: PropTypes.array,
};

export default CustomerDetail;
