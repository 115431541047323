import { useMemo, useState } from 'react';
import { useRecordContext } from 'react-admin';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ListSubheader, Tooltip, Typography } from '@mui/material';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';

import { AutocompleteSelectInput } from '@pumpkincare/shared';

import { DENIED, MGA_APPROVED } from '../../../lib/claims';
import { getDateAsFormattedUTC } from '../../../shared/utils';
import { formatCurrency } from '../../../shared/utils/currencyUtils';
import ClaimBulkEditSection from '../claim-bulk-edit-section';
import { claimAdjudicationDatagridStyle } from './claim-adjudication-datagrid-style';

const denied = DENIED.toLowerCase();
const approved = MGA_APPROVED.toLowerCase();

function renderServiceInputCell(params, serviceData) {
  return (
    <AutocompleteSelectInput
      {...params}
      isLoading={!serviceData?.length}
      options={serviceData}
      optionLabel='name'
      optionID='id'
    />
  );
}

function renderPreventProductInputCell(params, preventData) {
  return (
    <AutocompleteSelectInput
      {...params}
      isLoading={!preventData?.length}
      options={preventData}
      optionLabel='name'
      optionID='id'
      optionGrouper='category'
    />
  );
}

function formatProps(params, dataGridApi) {
  const isNotDecisionDenied = params.row.decision !== denied;
  const { id, value = [], field, onChange = () => {} } = params;

  const formattedValue =
    value === null || !Array.isArray(value) || isNotDecisionDenied ? [] : value;

  function handleChange(event, newOption) {
    dataGridApi.current.setEditCellValue({ id, field, value: event.target.value });
    dataGridApi.current.stopCellEditMode({ id: id, field: field });

    onChange(newOption);
  }

  return { isNotDecisionDenied, formattedValue, handleChange };
}

function renderPreventDenialList(params, preventDenialReason) {
  const dataGridApi = useGridApiContext();
  const { formattedValue, handleChange, isNotDecisionDenied } = formatProps(
    params,
    dataGridApi
  );

  return (
    <Select
      id='prevent-denial-reason'
      fullWidth
      value={formattedValue}
      onChange={handleChange}
      input={<Input />}
      disabled={isNotDecisionDenied}
    >
      {preventDenialReason.map(denialReason => (
        <MenuItem key={denialReason.value} value={denialReason.value}>
          {denialReason.label}
        </MenuItem>
      ))}
    </Select>
  );
}

function renderInsuranceDenialList(params, insuranceDenialReason) {
  const dataGridApi = useGridApiContext();
  const { formattedValue, handleChange, isNotDecisionDenied } = formatProps(
    params,
    dataGridApi
  );

  return (
    <Select
      id='insurance-denial-reason'
      multiple
      fullWidth
      value={formattedValue}
      onChange={handleChange}
      input={<Input />}
      disabled={isNotDecisionDenied}
    >
      <ListSubheader disableSticky>Standard Reasons</ListSubheader>

      {insuranceDenialReason
        .filter(denialReason => denialReason.category === 'Standard Reasons')
        .map(denialReason => (
          <MenuItem key={denialReason.id} value={denialReason.id}>
            {denialReason.name}
          </MenuItem>
        ))}
      <ListSubheader disableSticky>Exclusions</ListSubheader>
      {insuranceDenialReason
        .filter(denialReason => denialReason.category === 'Exclusions')
        .map(denialReason => (
          <MenuItem key={denialReason.id} value={denialReason.id}>
            {denialReason.name}
          </MenuItem>
        ))}
    </Select>
  );
}

export default function ClaimAdjudicationDatagrid(props) {
  const record = useRecordContext();
  const classes = claimAdjudicationDatagridStyle();
  const {
    renderMoneyInput,
    handleNextButtonclick,
    rows,
    pepRows,
    setRows,
    setPepRows,
    toggleRowEditing,
    onCommitCellChanges,
    onCommitPepCellChanges,
    insuranceLineItems,
    preventLineItems,
    denialReasonData = [],
    insuranceDenialReason = [],
    preventProductsData = {},
    serviceData = [],
    incidentHistoryOptions = [],
  } = props;
  const { totalClaimed } = useMemo(
    () => ({
      totalClaimed: rows.reduce((sum, item) => sum + item.claimed_amount, 0),
    }),
    [rows]
  );

  const [insuranceCheckedRows, setInsuranceCheckedRows] = useState([]);
  const [preventCheckedRows, setPreventCheckedRows] = useState([]);
  const [insuranceBulkChanges, setInsuranceBulkChanges] = useState({
    loss_date: '',
    service: {
      id: '',
      name: '',
    },
    incident: {
      id: '',
    },
    decision: '',
    denial_reason: [],
  });
  const [preventBulkChanges, setPreventBulkChanges] = useState({
    loss_date: '',
    item_type: {
      id: '',
    },
    decision: '',
    denial_reason: '',
  });

  const { totalPepClaimed } = useMemo(
    () => ({
      totalPepClaimed: pepRows.reduce((sum, item) => sum + item.claimed_amount, 0),
    }),
    [pepRows]
  );

  function handleInsuranceBulkEditClick() {
    const updatedRows = rows.map(row => {
      if (insuranceCheckedRows.some(id => row.id === id)) {
        if (!!insuranceBulkChanges.loss_date) {
          row.loss_date = insuranceBulkChanges.loss_date;
        }
        if (!!insuranceBulkChanges.service.id) {
          row.service = insuranceBulkChanges.service;
        }
        if (!!insuranceBulkChanges.decision) {
          row.decision = insuranceBulkChanges.decision;
          if (insuranceBulkChanges.decision === approved) {
            row.denial_reason = '';
          }
        }
        if (insuranceBulkChanges.denial_reason.length > 0) {
          row.denial_reason = insuranceBulkChanges.denial_reason;
        }
        if (!!insuranceBulkChanges.incident.id) {
          row.incident = insuranceBulkChanges.incident.id;
        }
      }
      return row;
    });
    setRows(updatedRows);
    resetInsuranceBulkEdit();
    handleNextButtonclick({ ...record, step: 2 });
  }

  function handlePreventBulkEditClick() {
    const updatedRows = pepRows.map(row => {
      if (preventCheckedRows.some(id => row.id === id)) {
        if (!!preventBulkChanges.loss_date) {
          row.loss_date = preventBulkChanges.loss_date;
        }
        if (!!preventBulkChanges.item_type.id) {
          row.item_type = preventBulkChanges.item_type;
        }
        if (!!preventBulkChanges.decision) {
          row.decision = preventBulkChanges.decision;
          if (preventBulkChanges.decision === approved) {
            row.denial_reason = '';
            row.approved_amount = row.claimed_amount;
            row.declined_amount = 0;
            row.approved_qty = row.quantity;
          } else {
            row.approved_amount = 0;
            row.approved_qty = 0;
            row.declined_amount = row.claimed_amount;
          }
        }
        if (!!preventBulkChanges.denial_reason) {
          row.denial_reason = preventBulkChanges.denial_reason;
        }
      }
      return row;
    });
    setPepRows(updatedRows);
    resetPreventBulkEdit();
    handleNextButtonclick({ ...record, step: 2 });
  }

  function resetInsuranceBulkEdit() {
    setInsuranceBulkChanges({
      loss_date: '',
      service: {
        id: '',
        name: '',
      },
      incident: {
        id: '',
      },
      decision: '',
      denial_reason: [],
    });
    setInsuranceCheckedRows([]);
  }

  function resetPreventBulkEdit() {
    setPreventBulkChanges({
      loss_date: '',
      item_type: {
        id: '',
      },
      decision: '',
      denial_reason: '',
    });
    setPreventCheckedRows([]);
  }

  const is_wellness = record.subclaims.some(sub => sub.type === 'wellness');
  const denialReasonListPrevent = denialReasonData
    .filter(denial =>
      is_wellness ? denial.type === 'wellness' : denial.type === 'prevent'
    )
    .map(denial => {
      return {
        label: denial.name,
        value: denial.id,
      };
    });

  const insuranceColumns = [
    {
      field: 'loss_date',
      headerName: 'Loss Date',
      type: 'date',
      width: 100,
      editable: true,
      headerAlign: 'left',
      align: 'left',
      valueGetter: params => {
        return params?.value
          ? new Date(getDateAsFormattedUTC(new Date(params.value), 'MM/DD/YYYY'))
          : null;
      },
      sortable: false,
      hide: false,
    },
    {
      field: 'description',
      headerName: 'Line Item',
      width: 200,
      editable: true,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: params =>
        params.value?.length > 28 ? (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement='top'
            title={params.value}
          >
            <span>
              {String(params.value).substring(0, 28)}
              ...
            </span>
          </Tooltip>
        ) : (
          params.value
        ),
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      type: 'number',
      editable: true,
      headerAlign: 'left',
      width: 90,
      align: 'left',
      sortable: false,
    },
    {
      field: 'service',
      headerName: 'Service',
      type: 'string',
      editable: true,
      headerAlign: 'left',
      width: 290,
      align: 'left',
      sortable: false,
      renderCell: params => {
        if (params.value?.name) {
          return params.value?.name.length > 35 ? (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement='top'
              title={params.value.name}
            >
              <span>
                {String(params.value.name).substring(0, 35)}
                ...
              </span>
            </Tooltip>
          ) : (
            params.value.name
          );
        }
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            Select a Service
            <ArrowDropDownIcon />
          </span>
        );
      },
      renderEditCell: params => renderServiceInputCell(params, serviceData),
    },
    {
      field: 'incident',
      headerName: 'Incident',
      type: 'singleSelect',
      headerAlign: 'left',
      editable: true,
      width: 170,
      align: 'left',
      sortable: false,
      renderCell: params => {
        if (!incidentHistoryOptions.length) {
          return '';
        } else if (params.value) {
          let label;
          label = incidentHistoryOptions.find(
            incident => incident.value === params.value
          ).toolTip;

          return params.value.length > 20 ? (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement='top'
              title={label}
            >
              <span>
                {label?.substring(0, 20)}
                ...
              </span>
            </Tooltip>
          ) : (
            params.value
          );
        } else
          return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Select an Incident
              <ArrowDropDownIcon />
            </span>
          );
      },
      valueOptions: incidentHistoryOptions,
    },
    {
      field: 'claimed_amount',
      headerName: 'Amount',
      type: 'number',
      headerAlign: 'left',
      editable: true,
      width: 100,
      align: 'left',
      sortable: false,
      renderCell: renderMoneyInput,
    },
    {
      field: 'decision',
      headerName: 'Decision',
      type: 'singleSelect',
      headerAlign: 'left',
      editable: true,
      width: 130,
      align: 'left',
      sortable: false,
      renderCell: params => {
        let decision = (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            Select Decision
            <ArrowDropDownIcon />
          </span>
        );
        if (params.value === approved) {
          decision = 'Approved';
        } else if (params.value === denied) {
          decision = 'Denied';
        }
        return decision;
      },
      valueOptions: [
        { label: 'Approved', value: approved },
        { label: 'Denied', value: denied },
      ],
    },
    {
      field: 'deductible_amount',
      headerName: 'Deductible',
      type: 'number',
      headerAlign: 'left',
      editable: false,
      width: 100,
      align: 'left',
      sortable: false,
      renderCell: renderMoneyInput,
    },
    {
      field: 'reimbursible_amount',
      headerName: 'Paid',
      type: 'number',
      headerAlign: 'left',
      editable: false,
      width: 100,
      align: 'left',
      sortable: false,
      renderCell: renderMoneyInput,
    },
    {
      field: 'denial_reason',
      headerName: 'Denial Reason',
      type: 'string',
      editable: true,
      headerAlign: 'left',
      width: 200,
      align: 'left',
      sortable: false,
      renderCell: params => {
        if (
          Array.isArray(params.value) &&
          params.value?.length > 0 &&
          insuranceDenialReason.length > 0
        ) {
          return params.value.map((param, index) => {
            return `${
              insuranceDenialReason.find(denial => denial.id === param).code
            }${index + 1 < params.value.length ? ', ' : ''}`;
          });
        } else {
          return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Select a Denial Reason
              <ArrowDropDownIcon />
            </span>
          );
        }
      },
      renderEditCell: params =>
        renderInsuranceDenialList(params, insuranceDenialReason),
    },
  ];

  const preventColumns = [
    {
      field: 'loss_date',
      headerName: 'Loss Date',
      valueGetter: params => {
        return params?.value
          ? new Date(getDateAsFormattedUTC(new Date(params.value), 'MM/DD/YYYY'))
          : null;
      },
      type: 'date',
      width: 100,
      editable: true,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      hide: false,
    },
    {
      field: 'description',
      headerName: 'Line Item',
      width: 200,
      editable: true,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: params =>
        params.value?.length > 28 ? (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement='top'
            title={params.value}
          >
            <span>
              {String(params.value).substring(0, 28)}
              ...
            </span>
          </Tooltip>
        ) : (
          params.value
        ),
    },
    {
      field: 'quantity',
      headerName: 'Qty',
      type: 'number',
      editable: true,
      headerAlign: 'left',
      width: 90,
      align: 'left',
      sortable: false,
    },
    {
      field: 'item_type',
      headerName: 'Item Type',
      type: 'string',
      editable: true,
      headerAlign: 'left',
      width: 290,
      align: 'left',
      sortable: false,
      renderCell: params => {
        if (params.value?.name) {
          return params.value?.name.length > 35 ? (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement='top'
              title={params.value.name}
            >
              <span>{String(params.value.name).substring(0, 35)}...</span>
            </Tooltip>
          ) : (
            params.value.name
          );
        }

        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            Select Item Type
            <ArrowDropDownIcon />
          </span>
        );
      },
      renderEditCell: params =>
        renderPreventProductInputCell(params, preventProductsData),
    },
    {
      field: 'claimed_amount',
      headerName: 'Amount',
      type: 'number',
      headerAlign: 'left',
      editable: true,
      width: 100,
      align: 'left',
      sortable: false,
      renderCell: renderMoneyInput,
    },
    {
      field: 'decision',
      headerName: 'Decision',
      type: 'singleSelect',
      headerAlign: 'left',
      editable: true,
      width: 130,
      align: 'left',
      sortable: false,
      renderCell: params => {
        let decision = (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            Select Decision
            <ArrowDropDownIcon />
          </span>
        );
        if (params.value === approved) {
          decision = 'Approved';
        } else if (params.value === denied) {
          decision = 'Denied';
        }
        return decision;
      },
      valueOptions: [
        { label: 'Approved', value: approved },
        { label: 'Denied', value: denied },
      ],
    },
    {
      field: 'approved_qty',
      headerName: 'Approved Qty',
      type: 'number',
      headerAlign: 'left',
      editable: true,
      width: 130,
      align: 'left',
      sortable: false,
    },
    {
      field: 'approved_amount',
      headerName: 'Approved Amount',
      type: 'number',
      headerAlign: 'left',
      editable: true,
      width: 150,
      align: 'left',
      sortable: false,
      renderCell: renderMoneyInput,
    },
    {
      field: 'declined_amount',
      headerName: 'Declined Amount',
      type: 'number',
      headerAlign: 'left',
      editable: true,
      width: 150,
      align: 'left',
      sortable: false,
      renderCell: renderMoneyInput,
    },
    {
      field: 'denial_reason',
      headerName: 'Denial Reason',
      type: 'string',
      headerAlign: 'left',
      editable: true,
      width: 200,
      align: 'left',
      sortable: false,
      renderCell: params => {
        if (params.value && denialReasonListPrevent.length > 0) {
          const label = denialReasonListPrevent.find(
            reason => reason.value === params.value
          ).label;
          return label.length > 25 ? (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement='top'
              title={label}
            >
              <span>{label.substring(0, 25)}...</span>
            </Tooltip>
          ) : (
            label
          );
        } else {
          return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Select Denial Reason
              <ArrowDropDownIcon />
            </span>
          );
        }
      },
      renderEditCell: params =>
        renderPreventDenialList(params, denialReasonListPrevent),
    },
  ];

  return (
    <div>
      {insuranceLineItems ? (
        <>
          <div
            style={{ display: 'flex', marginBottom: '24px', alignItems: 'center' }}
          >
            <Typography className={classes.title}>
              {insuranceLineItems.id}
            </Typography>
          </div>

          {insuranceCheckedRows.length > 0 ? (
            <ClaimBulkEditSection
              serviceData={serviceData}
              subclaimType='insurance'
              incidentHistoryOptions={incidentHistoryOptions}
              denialReasonList={insuranceDenialReason}
              onBulkEditCancelClick={resetInsuranceBulkEdit}
              updateBulkChanges={setInsuranceBulkChanges}
              onBulkEditUpdateClick={handleInsuranceBulkEditClick}
              bulkChanges={insuranceBulkChanges}
            />
          ) : null}
          <DataGrid
            rows={rows}
            sortingMode='server'
            disableColumnMenu
            sx={{
              '& .MuiDataGrid-row': {
                '&:nth-of-type(2n-1)': {
                  backgroundColor: 'rgba(0, 125, 255, 0.08)',
                },
              },
            }}
            checkboxSelection={true}
            onRowSelectionModelChange={insuranceCheckedIds => {
              setInsuranceCheckedRows(insuranceCheckedIds);
            }}
            onCellEditStart={() => toggleRowEditing()}
            rowSelectionModel={insuranceCheckedRows}
            ColumnSortedAscendingIcon={null}
            disableColumnFilter
            disableColumnSelector
            disableRowSelectionOnClick
            disableDensitySelector
            processRowUpdate={(updatedRow, originalRow) =>
              onCommitCellChanges(originalRow, updatedRow)
            }
            columns={insuranceColumns}
            autoHeight={true}
            hideFooter={true}
          />
          <div style={{ display: 'flex', marginLeft: '45%', marginTop: '40px' }}>
            <Typography style={{ textAlign: 'left' }}>Total:</Typography>
            <Typography style={{ marginLeft: '130px' }}>
              {formatCurrency(totalClaimed, { areCents: false })}
            </Typography>
          </div>
        </>
      ) : null}

      {preventLineItems ? (
        <>
          <div
            style={{ display: 'flex', marginBottom: '24px', alignItems: 'center' }}
          >
            <Typography className={classes.title}>{preventLineItems.id}</Typography>
          </div>
          {preventCheckedRows.length > 0 ? (
            <ClaimBulkEditSection
              preventProductsData={preventProductsData}
              subclaimType='prevent'
              denialReasonList={denialReasonListPrevent}
              incidentHistoryOptions={incidentHistoryOptions}
              onBulkEditCancelClick={resetPreventBulkEdit}
              updateBulkChanges={setPreventBulkChanges}
              onBulkEditUpdateClick={handlePreventBulkEditClick}
              bulkChanges={preventBulkChanges}
            />
          ) : null}
          <DataGrid
            rows={pepRows}
            sortingMode='server'
            disableColumnMenu
            classes={{
              root: classes.preventAdjudicationDatagrid,
            }}
            onCellEditStart={() => toggleRowEditing()}
            ColumnSortedAscendingIcon={null}
            checkboxSelection={true}
            onRowSelectionModelChange={preventCheckIds => {
              setPreventCheckedRows(preventCheckIds);
            }}
            rowSelectionModel={preventCheckedRows}
            disableColumnFilter
            disableColumnSelector
            disableRowSelectionOnClick
            disableDensitySelector
            processRowUpdate={(updatedRow, originalRow) =>
              onCommitPepCellChanges(originalRow, updatedRow)
            }
            columns={preventColumns}
            autoHeight={true}
            hideFooter={true}
          />
          <div style={{ display: 'flex', marginLeft: '31%', marginTop: '40px' }}>
            <Typography style={{ textAlign: 'left' }}>Total:</Typography>
            <Typography style={{ marginLeft: '130px' }}>
              {formatCurrency(totalPepClaimed, { areCents: false })}
            </Typography>
          </div>
        </>
      ) : null}
    </div>
  );
}
