import makeStyles from '@mui/styles/makeStyles';

const customerDetailStyle = makeStyles(
  {
    fontStyle: {
      fontSize: '14px',
      fontWeight: '700',
    },
    titleStyle: {
      fontSize: '18px',
      fontWeight: '600',
    },
    root: {
      width: '1205px',
    },
    renewalToday: {
      color: '#4863A0',
      fontSize: '14px',
      fontWeight: '700',
    },
    renewalUpcoming: {
      color: '#FFA500',
      fontSize: '14px',
      fontWeight: '700',
    },
    cancelledStatus: {
      color: '#FF6E6B',
      fontSize: '14px',
      fontWeight: '700',
    },
    cancelPets: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '24px',
    },
    cancelPetsTitle: {
      fontSize: '16px',
      fontWeight: '400',
      marginRight: '16px',
    },
    linkStyle: {
      fontWeight: '400',
      fontSize: '16px',
      color: '#2962FF',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      textDecoration: 'none',
    },
    thirdPartyAlert: {
      padding: '6px 16px',
      background: '#eef0fe',
      marginBottom: '16px',
    },
    thirdParty: {
      display: 'flex',
      flexDirection: 'row',
      gap: '2px',
      alignItems: 'center',
    },
    thirdPartyIcon: {
      color: '#0288d1',
    },
  },
  { name: 'customer-detail' }
);

const sxCustomerDetailStyle = {
  headerCell: {
    fontSize: '12px',
    fontWeight: '800',
    lineHeight: '32px',
    letterSpacing: '1px',
    color: '#9B9FA8',
    borderBottom: '0px',
    padding: '0px',
  },
  table: {
    borderBottom: '0px',
  },
  labelCell: {
    color: '#9B9FA8',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '1px',
  },
  rowCell: {
    borderBottom: '0px',
    padding: '0px',
  },
};

export { customerDetailStyle, sxCustomerDetailStyle };
